<template>
  <div class="row" v-if="asignacion">
    <div class="col">
      <!-- Si ya existe reconocimiento de local -->
      <!-- DEBE EXISTIR TAREAS OBVIAMENTE PARA ESTA PARTE -->
      <template>
        <div class="d-flex justify-content-between align-items-center">
          <p class="h5 detalles-asignacion__sub font-italic my-0">
            Partidas registradas
          </p>
        </div>

        <hr />

        <p class="small mt-1 text-center font-italic" v-if="loading">
          <template>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Obteniendo Partidas</span>
          </template>
        </p>

        <!-- MUESTRA DE TAREAS ASIGNADAS -->
        <section class="mt-3" v-else>
          <div class="row" v-if="tareas.length">
            <div
              class="col-md-12 mb-3"
              v-for="tarea in tareas"
              :key="tarea.tareaId"
            >
              <!-- Muestra un card con las tareas, cada card es collapse y muestra info detallada de cada tarea -->
              <b-card class="bg-dark">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <a
                      class="text-white"
                      href=""
                      v-b-toggle="tarea.tareaId"
                      @click.prevent
                      variant="info"
                      >{{ tarea.descripcion }}</a
                    >
                  </div>
                </div>

                <b-collapse :id="tarea.tareaId" class="mt-2">
                  <hr />

                  <b-card>
                    <p>
                      <i class="fas fa-calendar-alt"></i>
                      {{ tarea.created_at | moment("DD/MM/YYYY") }}
                    </p>

                    <small class="text-muted">Observaciones:</small>

                    <p class="card-text my-1 pb-1 border-bottom">
                      {{ tarea.observacion }}
                    </p>

                     <div class="row mt-2">
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted">Descripción</span>
                          <span class="ml-2">
                            {{ tarea.descripcion }}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div class="row mt-2">
                
                      <div class="col-md-12">
                        <p class="my-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Horario asignado:</span
                          >
                          <span class="ml-2">{{
                            tarea.horario === 0 ? "Diurno" : "Nocturno"
                          }}</span>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-md-4">
                        <p class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted">Medidas:</span>
                          <span class="ml-2"
                            >{{ tarea.cantidad }} ({{
                              tarea.unidadMedida
                            }})</span
                          >
                        </p>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="mt-3 mb-1 pb-1 border-bottom">
                          <span class="small text-muted"
                            >Estado de la Tarea:</span
                          >
                          <b-badge
                            class="px-4 py-2 ml-3"
                            pill
                            :variant="getEstadoTarea(tarea)[1]"
                            >{{ getEstadoTarea(tarea)[0] }}</b-badge
                          >
                        </div>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { estadosTareas } from "../../../../.env.js";
import { precio } from "@/mixins/precio";
import regexMixin from "@/mixins/regexMixins";
import {
  hideAdmin,
  hideContratante,
  hideSupervisor,
} from "@/directives/roleDirective.js";

import GetAllTareas from "@/apollo/queries/tareas/GetAllTareas.gql";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";

export default {
  directives: { hideAdmin, hideContratante, hideSupervisor },

  mixins: [precio, regexMixin],
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tareas: [],
      asignacion: null,
      estadosTareas: estadosTareas,
    };
  },

  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  methods: {
    getEstadoTarea(item) {
      let estado = this.estadosTareas.find((e) => e.value == item.estado);

      return [estado["title"], estado["variant"]];
    },
    setSubtotal(precio, cantidad) {
      return parseFloat(precio) * parseInt(cantidad);
    },

    init() {
      return new Promise((resolve) => {
        this.getInfo().then(() => {
          this.getTareas();

          resolve();
        });
      });
    },
    getInfo() {
      return new Promise((resolve) => {
        this.$apollo
          .query({
            query: GetAsignaciones,
            variables: {
              codigoTicket: this.id,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.asignacion = response.data.GetAsignaciones;

            resolve();
          });
      });
    },

    getTareas() {
      this.loading = true;

      this.$apollo
        .query({
          query: GetAllTareas,
          variables: {
            numberPaginate: 100,
            page: 1,
            codigoTicket: this.id,
            estado: "",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          this.tareas = res.data.GetAllTareas.data;

          this.tareas.forEach((item) => {
            item.copiaPrecioContratista = item.precioContratista;
          });

          this.loading = false;
        });
    },
  },
};
</script>
